import { GET_SEARCH_RESULTS, SEARCH_RESULTS } from "./ActionTypes";
    
export const getResults = (payload) => {
    return {
    type: GET_SEARCH_RESULTS,
    payload 
    };
};

    
export const saveResults = (payload) => {
    return {
    type: SEARCH_RESULTS,
    payload 
    };
};