import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { authToken, temp_authToken, previewMode, goToTeam } from 'Redux/Actions/Auth';
import { SIGN_UP_LIMIT, STRINGS } from 'Shared/Constants';
import { Images } from 'Shared/Images';
import PopupComingSoon from '../PopupComingSoon';
import { storeNodeMap } from "Redux/Actions/Nodes";
import { clearWellnessMap, clearFeeligsMap } from 'Redux/Actions';
import './header-styles.scss';
import SummeryTitle from '../SummeryTitle';
import { ROUTE_CONSTANTS } from 'Shared/Routes';
function Header({ open = () => { }, toggleRef }) {


	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state) => state?.auth?.userDetails);
	const searchRef = useRef(null);
	useEffect(() => {
		if (history?.location?.pathname?.split('/')?.[1] === 'search' && history?.location?.pathname?.split('/')?.[2]) {
			searchRef.current.value = decodeURIComponent(history?.location?.pathname?.split('/')?.[2] ?? '');
		}
	}, [history])
	const logout = () => {
		searchRef.current.value = ''
		handleSearch()
		dispatch(authToken(null));
		dispatch(temp_authToken(null));
		dispatch(previewMode(false));
		dispatch(storeNodeMap(null));
		dispatch(clearWellnessMap());
		dispatch(clearFeeligsMap());
		dispatch(goToTeam(false));
		localStorage.setItem("Current_user_email", null);
		setTimeout(() => {
			history.push("/");
		}, 500);
	};

	const LogoutValue = useSelector((state) => state.auth.token);

	const toggleIcon = useRef(null);
	const handleBurgerMenuClick = () => {
		if (window.getComputedStyle(toggleIcon?.current).display !== 'none') {
			open();
		}
	};

	const handleSearch = useCallback(function () {
		const search = searchRef.current.value
		history.push(ROUTE_CONSTANTS.SEARCH.replace(':id', encodeURIComponent(search)));
	}, []);

	return (
		<>
			<div className="top-wrapper">
				<header className="main_header">
					<div className="container-fluid">
						<div className="row cstm_head align-items-center">
							<div className="d-flex align-items-center justify-content-end left_secn for_mob ">
								<Link className='navbar-brand d-block d-lg-none'
									to={{
										pathname: '/dashboard'
									}}>

									<img src={Images.logo} className="img-fluid" alt="logo" /></Link>
							</div>
							<div className="d-flex align-items-center justify-content-between right_secn for_mob ">
								<div className="header_left d-flex ">
									<a
										rel="noreferrer"
										href={STRINGS.CANCER_CLARITY_URL}
										target="_blank"
										className="btn btn-primary me-3  cstm_btn "><img src={Images.logo} className="img-fluid w-25" alt="logo" />CancerClarity</a>
									<a
										rel="noreferrer"
										href={STRINGS.REVOLUTION_CANCER_URL}
										target="_blank"
										className="btn btn-primary d-md-none d-lg-flex d-none  me-3 cstm_btn  tele_btn "><img src={Images.Rc_icon} className="img-fluid w-25" alt="logo" />Revolution Cancer</a>
								</div>
								<div className="header_right">
									<ul className="d-flex list-unstyled header_right_list flex-wrap">
										<li className="sign-in-user">
											<Link to="/login" className="signup common-link"><img src={Images.User} className="img-fluid" alt="user-icon" />
												<SummeryTitle
													noClass={!!LogoutValue}
													limit={SIGN_UP_LIMIT}
													text={!LogoutValue ? 'Sign In' : user?.first_name} /></Link>
										</li>
										{LogoutValue ? <li className="sign-in-user signout">
											<button className="sign-out common-link" onClick={() => { logout(); }}>
												<img src={Images.Signout} className="img-fluid" alt="user-icon" /> Sign Out
											</button>
										</li> : null}
										{!LogoutValue ? <li className="signup-user">
											<Link to="/signup" className="signup common-link">Sign Up</Link>
										</li> : null}
										<li className="search-bar">
											<div className="search_form">
												<input ref={searchRef} type="text" className="form-control" placeholder="SEARCH"
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															handleSearch();
														}
													}} />

												<em className="btn btn-white search_form_btn" type="button">
													<img onClick={handleSearch} src={Images.Search} className="img-fluid" alt="search-icon" width={25} height={25} title='search' />
												</em>

											</div>
										</li>
										<li className="contact_link">
											<button className="btn btn-primary cstm_btn" onClick={() => history.push("/contactus")}>Contact us</button>
										</li>
										<li className="about_link">
											<button className="btn btn-primary cstm_btn" onClick={() => history.push("/about")}>About us</button>
										</li>
										<button className="toggle_icon"
											ref={(ref) => {
												toggleRef(ref);
												toggleIcon.current = ref;
											}}
											onClick={handleBurgerMenuClick}>
											<img src={Images.BarIcon} width="40px" alt="" /></button>
									</ul>

								</div>
							</div>
						</div>
					</div>
				</header>
				<PopupComingSoon />
			</div>
		</>);

}

export default Header;