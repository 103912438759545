export const ROUTE_CONSTANTS = {
    LOGIN: "/",
    DASHBOARD: "/dashboard",
    FORGOT_PASSWORD: "/forgotPassword",
    FORGOT_PASSWORD_CONFIRM: "/reset-password/:token",
    // RESET_PASSWORD: "/reset-password/:token",
    
    CLINICAL_TRAILS: "/clinical-trails",
    FEELING_AND_SYMPTOMS: "/feeling-and-symptoms",
    MENTAL_HEALTH: "/mental-health",
    MENTAL_HEALTH_CENTER: "/mental-health-categories-center",
    MENTAL_HEALTH_VIDEO: "/mental-health-healing-video-path/",
    PROFILE: "/dashboard",
    SUPPORT_GROUPS: "/support-groups",
    SUPPORT_GROUP_HUB: "/my-support-group-hub",
    SUPPORT_GROUP_HUB_REQUEST_APPROVAL: "/my-support-group-request/:id",
    SUPPORT_GROUP_HUB_JOINED: "/my-support-group-joined",
    WELLNESS_CENTER: "/wellness-center",
    FITNESS_AND_EXERCISE: "/wellness-center-categories",
    FEELING_CENTER: "/mental-health-categories",
    HELP_HOTLINES: "/help-hotlines",
    PROFILE_UPDATE: "/profile-update",
    TEAM_PAGE:"/team/team-members/:id",
    EDIT_PROFILE: "/edit-profile",
    CHECKLIST: "/checklist",
    PATIENT_SUPPORT: "/patient-support",
    CATEGORY_RESULT: "/category-result",
    NUTRITION: "/nutrition",
    CANCER_MANAGEMENT: "/cancer-management",
    STRESS_MANAGEMENT: "/stress-managemenet",
    COMMON_CHECKLIST: "/common-checklist",
    WELLNESS_CENTER_FITNESS: "/wellness-centre-fitness",
    REACH_OUT_TO_US : "/mental-health-reach-out-to-us/:id",
    RESOURCES_FOR_YOU : "/resources-for-you",
    ADD_POST: "/add-post",
    ONLINE_CHAT_FORUMS: "/online-chat-forums",
    THREADS: "/threads",
    CURRENT_POST: "/thread-current-post/:id",
    COMMUNITY_GROUP: "/online-chat-forum-group",
    MEDIA_PLAYER: "/media-player/:id",
    MEDIA_PLAYER_FEELINGS: "/media-player-feelings/:id",
    EDIT_POST_LIST: "/user/threads/",
    EDIT_CURRENT_POST: "/user/thread/edit/",
    CHANGE_PASSWORD: "/change-password",
    TEAM_INVITATION: "/team",

    //CARE_CORDINATOR
    CARE_CORDINATOR: "/care-coordinator",
    MY_CANCERLIGHT_DIARY: "/care-coordinator-my-cancerlight-diary/:tracker",
    MY_MEDICATION: "/care-coordinator-my-medication",
    MY_NOTES: "/care-coordinator-my-notes",
    MY_SYMPTOM: "/care-coordinator-my-symptom",
    MY_SURVIVORSHIP: "/care-coordinator-my-survivorship",
    MY_NUTRITION: "/care-coordinator-my-nutrition",
    CANCER_LIGHT_WORLD: "/cancer-light-world",

    // Wellness Center
    CANCER_REHABILITATION_VIDEO: "/cancer-rehabilation",
    EVENT_CALENDAR: "/event-calendar",
    JOUNREY_MAP: "/wellness-center-categories-journey-map",
    FITNESS_JOUNREY_MAP_MEDIA: "/journey-map/media-player/:id",
    RESOURCES_FOR_YOU_FILE : "/resources-for-you/list/:name/:id/:resourceType/:resourceId",
    WELLNESS_CENTER_CATEGORY_THERAPY: '/wellness-center-category/therapy/:id',
    // Video History
    VIDEO_HISTORY: "/video-history",
    THERAPY_CATEGORIES: '/therapy-categories/:id/',
    THERAPY_CATEGORIES_MAP: '/therapy-categories-map/:id/',
    THERAPY_CATEGORIES_MAP_MEDIA: '/therapy-map/media-player/:id',
    THERAPY_PARTNERSITES : '/therapy-partner-sites/:id',
    MY_CHEMO_THERAPY_TRACKER_ADD :  "/care-coordinator-my-chemo",
    MY_RADIATION_THERAPY_TRACKER_ADD :  "/care-coordinator-my-radiation",
    MY_SURGERY_THERAPY_TRACKER_ADD :  "/care-coordinator-my-surgery",
    MY_SURGERY_OTHER_TREATMENTS_TRACKER_ADD :  "/care-coordinator-my-other-treatments",
    MY_APPOINTMENTS_TRACKER_ADD :  "/care-coordinator-my-appointments/:id",
    CARE_CORDINATOR_PERSONAL_TRACKERS :  "/care-coordinator/personal-trackers",
    CARE_CORDINATOR_TREATMENT_TRACKERS :  "/care-coordinator/treatment-trackers",
    CARE_CORDINATOR_APPOINTMENT_TRACKERS :  "/care-coordinator/appointment-trackers",
    MY_CANCERLIGHT_DIARY_TRACKERS: "/care-coordinator/cancer-light-diary-trackers",
    USER_PREFRENCES: "edit-prefrence",
    SYMPTOMS_AND_SUPPORTIVE_CARE_MAIN: "/symptoms-and-supportive-care/",
    SYMPTOMS_AND_SUPPORTIVE_CARE: '/symptoms-and-supportive-care/:slug',
    SYMPTOMS_AND_SUPPORTIVE_CARE_SEARCH: '/symptoms-and-supportive-care/search/:slug',
    SYMPTOMS_AND_SUPPORTIVE_CARE_OPTIONS: '/symptoms-and-supportive-care/details/:slug/:id/',
    SYMPTOMS_AND_SUPPORTIVE_CARE_MAP: '/symptoms-and-supportive-care/map/:slug/:id/',
    SYMPTOMS_AND_SUPPORTIVE_CARE_MEDIA_PLAYER: '/symptoms-and-supportive-care/player/:slug/:id/',
    SYMPTOMS_AND_SUPPORTIVE_CARE_REACH_OUT_TO_US: '/symptoms-and-supportive-care/reach-out-to-us/:slug/:id/',
    PALLIATIVE_CARE_AND_HOSPICE: "/palliative-care-and-hospice/",
    PALLIATIVE_CARE_AND_HOSPICE_DETAILS: "/palliative-care-and-hospice/details/:slug/",
    PALLIATIVE_CARE_AND_HOSPICE_MAP: "/palliative-care-and-hospice/map/:slug/",
    PALLIATIVE_CARE_AND_HOSPICE_MAP_MEDIA_PLAYER: "/palliative-care-and-hospice/player/:slug/:id/",
    PARTNER_SITES_PALLIATIVE_AND_HOSPICE: "/palliative-care-and-hospice/partner-sites/:slug/:id/",
    CONATCT_US: '/contactus',
    ABOUT_US: '/contactus',
    USER_LOGIN: '/login',
    SIGNUP: '/signup',
    QUESTION_ONE:'/questionnaireone',
    MENTAL_HEALTH_VIDEO_PATH:'/mental-health-feeling-video-path',
    QUESTION_TWO:'/questionnairetwo',
    EDIT_PREFRENCE: '/edit-prefrence',
    ABOUT_US_PAGE: '/about',
    INSTA_LOGIN: '/insta-login',
    VIEW_MAP:'/view-map',
    SEARCH:'/search/:id',
    REACT_NATIVE_VIDEO_PLAYER:'/rnvideo'
  };
  