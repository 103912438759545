import { lazy } from "react";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import InstagramLogin from "Views/Authentication/InstagramLogin";

const Threads = lazy(() => import("../Views/Authentication/Threads"));
const ReachOutToUs = lazy(() => import("../Views/Authentication/ReachOutToUs"));
const About = lazy(() => import("../Views/Authentication/AboutUs"));
const ContactUs = lazy(() => import("../Views/Authentication/ContactUs"));
const CategoryResult = lazy(() => import("../Views/Authentication/CategoryResult"));
const PatientSupport = lazy(() => import("../Views/Authentication/PatientSupport"));
const SupportGroups = lazy(() => import("../Views/Authentication/SupportGroups"));
const OnlineChatForums = lazy(() => import("../Views/Authentication/OnlineChatForums"));
const ChangePassword = lazy(() => import("../Views/Authentication/ChangePassword"));
const MobileView = lazy(() => import("../Views/Authentication/MobileWebView"));
const SearchResults = lazy(() => import("../Views/Authentication/SearchResults"))
const ReactNativeMediaPlayer = lazy(() => import("../Views/Authentication/SearchResults/Components/ReactNativeMediaPlayer"))


export const PUBLIC_ROUTES = [
      {
        path: "/contactus",
        component: ContactUs,
        title: "Contact",
      },
      {
        path : "/about",
        component: About,
        title: "About"
      },
      
      {
        path : ROUTE_CONSTANTS.SUPPORT_GROUPS,
        component: SupportGroups,
        title: "Support Group"
      },
      {
        path : ROUTE_CONSTANTS.REACH_OUT_TO_US,
        component : ReachOutToUs,
        title : "Reach Out to us"
      },
      
      {
        path: ROUTE_CONSTANTS.CHANGE_PASSWORD,
        component: ChangePassword,
        title: "Change Password"
      },
      {
        path : ROUTE_CONSTANTS.PATIENT_SUPPORT,
        component: PatientSupport,
        title: "Patient Support"
      },
      {
        path : ROUTE_CONSTANTS.CATEGORY_RESULT,
        component: CategoryResult,
        title: "Category Result"
      },
      {
        path : ROUTE_CONSTANTS.ONLINE_CHAT_FORUMS,
        component : OnlineChatForums,
        title : "Online chat forums"
      },
      {
        path : ROUTE_CONSTANTS.THREADS,
        component: Threads,
        title: "Threads"
      },
      {
        path: ROUTE_CONSTANTS.INSTA_LOGIN,
        component: InstagramLogin,
        title: "Instagram Login"
      },
      {
        path:ROUTE_CONSTANTS.VIEW_MAP,
        component:MobileView,
        title:"Mobile View",
      },
  {
    path: ROUTE_CONSTANTS.REACT_NATIVE_VIDEO_PLAYER,
    component: ReactNativeMediaPlayer,
    title: "Mobile View",
  },
  {
    path: ROUTE_CONSTANTS.SEARCH,
    component: SearchResults,
    title: "Login",
    exact: true
  }
]
