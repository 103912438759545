import { all, fork } from "redux-saga/effects";

import authSaga from "./Auth";
import userSaga from "./User";
import mentalhealth from "./MentalHealth";
import supportgroups from "./SupportGroups";
import wellnesscenter from "./WellnessCenter";
import nodeSaga from "./Node";
import teamwork from "./Teamwork";
import carecoordinator from "./CareCoordinator";
import CheckList from "./CheckList";
import VideoHistory from "./VideoHistory";
import CalendarEvents from './CalendarEvents';
import SearchResult from './SearchResult';

// yield all([fork(watchAuth)]);

function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(nodeSaga)
  ]
  );
  yield all([fork(mentalhealth)])
  yield all([fork(supportgroups)])
  yield all([fork(carecoordinator)])
  yield all([fork(teamwork)])
  yield all([fork(wellnesscenter)])
  yield all([fork(CheckList)])
  yield all([fork(VideoHistory)])
  yield all([fork(CalendarEvents)])
  yield all([fork(SearchResult)])
}

export default rootSaga;
