import React  from "react";
import PropTypes from "prop-types";
import style from "./index.module.scss";
import ReactPlayer from "react-player";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";

import { videoAutoplayRequest, updateVidePreference } from '../../../Redux/Actions/Nodes';
import { connect } from 'react-redux';
import { withSnackbar } from "notistack";
import { handleCloseNotification } from 'Shared/Utilities'
import ReactPlayerControls from './ReactPlayerControls';
import PlayPauseIcons from './PlayPauseIcons';
import "./style.scss";
import MobileDuration from "./MobileDuration";
import { canFullScreen } from "Shared/Functions";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRefautoPlay = React.createRef(false);
    this.myRefautoPlayInit = React.createRef(false);
    this.state = {
      isOpenSpeed: false,
      url: this.props.mediaUrl,
      // url: null,
      pip: false,
      playing: false,
      controls: false,
      light: false,
      volume: 1,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      autoPlay: false,
      isFullScreen: false,
      isReload: true
    };
    this.timeoutRef = React.createRef(null);
    this.mobileControlRef = React.createRef(null);
    this.isPlayStateChanged = React.createRef(null);
    this.html5VideoPlayer = React.createRef(null);
  }

  handlePlayPause = () => {
    if (this.props.onHandlePlayPause) {
      this.props.onHandlePlayPause(!this.state.playing)
    }
    this.setState({ playing: !this.state.playing });
  };





  handleVolumeChange = (e) => {
    const volume = e / 100;
    this.handleVideoClick();
    this.setState({ volume: parseFloat(volume) });
    if (Number(volume) === 0) this.setState({ muted: true });
    else {
      this.setState({ muted: false })
    }
    if(!this.props?.isPreview) {
      this.handleVolumeChangeUpdate(parseFloat(volume), Number(volume) === 0)
    }
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
    if(!this.props?.isPreview) {
      this.handlePlayMuteUpdate(!this.state.muted)
    }
  };


  handleSetPlaybackRate = (e) => {
    if(!this.props?.isPreview) {
      this.handlePlaySpeedUpdate(parseFloat(e.target.value))
    }
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };


  handlePlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    // TODO: implementatin for mouse down eveny
  };

  handleSeekChange = (e) => {
    const progress = e / 100;
    if (this.props.handleSeekChange) {
      this.props.handleSeekChange()
    }
    this.setState(() => {
      this.setState(() => {
        this.player.seekTo(parseFloat(progress));
        this.setState({ seeking: false });
        return { played: parseFloat(progress) }
      });
      return { seeking: true }
    });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
  };


  handleVolumeChangeUpdate = (volume, muted) => {
    if (this.props.updateUserPreferences) {
      this.props.updateUserPreferences({
        videoVolume: volume.toFixed(2),
        videoMute: muted
      },
        (err) => {
          console.log(err)
        })
    }
  }

  handlePlaySpeedUpdate = (speed) => {
    if (this.props.updateUserPreferences) {
      this.props.updateUserPreferences({
        videoSpeed: speed.toFixed(4)
      },
        (err) => {
          console.log(err)
        })
    }
  }


  handlePlayMuteUpdate = (muted) => {
    if (this.props.updateUserPreferences) {
      this.props.updateUserPreferences({
        videoMute: muted
      },
        (err) => {
          console.log(err)
        })
    }
  }

  handleProgress = (state) => {
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
    if (this.props.videoEnded) {
      this.props.videoEnded();
    }
  };

  handleNextBtn = () => {
    this.setState({ playing: false });
    if (this.props.selectedVideoEnded) {
      this.props.selectedVideoEnded();
    }
  }

  handlePrevBtn = () => {
    this.setState({ playing: false });
    if (this.props.previousVideo) {
      this.props.previousVideo();
    }
  }

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };


  handleFullscreenChange = () => {
    this.fullscreenchanged(null);
  };

  turnOffFullscreenMode = () => {
    this.markFullscreenclose();
  };

  ref = (player) => {
    if(!this.html5VideoPlayer.current && player?.getInternalPlayer()) {
      this.html5VideoPlayer.current = player.getInternalPlayer();
      this.html5VideoPlayer.current.disablePictureInPicture = true;
      if(!canFullScreen()) {
        this.html5VideoPlayer.current.addEventListener('webkitendfullscreen',this.handleFullscreenChange);
        this.html5VideoPlayer.current.addEventListener('fullscreenchange',this.handleFullscreenChange);
        this.html5VideoPlayer.current.addEventListener('leavepictureinpicture',this.turnOffFullscreenMode);  
        
      }
      this.player = player;
    }
  };

  handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(findDOMNode(this.myRef.current),{navigationUI: 'hide'});
    } else if(this.html5VideoPlayer?.current) {
      this.html5VideoPlayer.current.webkitEnterFullscreen();
      this.html5VideoPlayer.current.controls = false;
      this.fullscreenchanged(null); 
    } else {
      if(this.props.fullscreenFailed) {
        this.props.fullscreenFailed()
      } 
    }
  };

  componentDidUpdate(prevProps) {
    if(!this.state.playing) {
      this.isPlayStateChanged.current = true
      this.toggleOpacity(1)
      if(this.mobileControlRef.current) {
        clearTimeout(this.mobileControlRef.current)
        this.mobileControlRef.current = null;
      }
    }

    if(this.state.playing && !this.mobileControlRef.current && this.isPlayStateChanged.current) {
      this.isPlayStateChanged.current = false;
      this.handleVideoClick()
    }
    if(prevProps.mediaUrl !== this.props?.mediaUrl) {
      this.setState({ url: this.props?.mediaUrl})
      const video = this.myRef.current.querySelector('video');
      video.playsinline = true;
      video.autoplay = true;
    }

    if (this.myRefautoPlayInit.current) {
      this.setState({ autoplay: this.props.autoplay });
      this.myRefautoPlayInit.current = false;
    }
    if (this.myRefautoPlay?.current && this.props.isVideoautoPlaySuccess && this.myRefautoPlay.current !== this.props.isVideoautoPlaySuccess) {
      this.myRefautoPlay.current = false;
      this.props?.enqueueSnackbar("Autoplay is turned " + (this.props.autoplay ? 'Off' : 'On'), {
        variant: "success",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        action: (key) => handleCloseNotification(this?.props?.closeSnackbar, key)
      });
      if (this.props.autoPlayChanged) {
        this.props.autoPlayChanged(!this.props.autoplay)
      }
    
   
    }


    if (this.props.noAutoPlay) {
      this.timeoutRef.current = setTimeout(() => {
        this.setState({ playing: false })
      }, 1000)
    }
  }


  toggleOpacity(value) {
    const playNextIcons = document.querySelector('.playNextIcons')
    const mobileToolbar = document.querySelector('.mobile-toolbar')
    if(playNextIcons) playNextIcons.style.opacity = value;
    if(mobileToolbar) mobileToolbar.style.opacity = value;
  }

  handleVideoClick() {
    this.toggleOpacity(1)
    if(this.mobileControlRef.current) {
      clearTimeout(this.mobileControlRef.current);
    }
    this.mobileControlRef.current = setTimeout(() => {
      this.mobileControlRef.current = null;
      this.toggleOpacity(0)
    },4000)
  }

  handleOnVideoReady() {
    if(this.myRef) {
      this.myRef.current.style.display = 'block';
      const videoBorder = document.querySelector('.video_node_secn')
      this?.props?.onloadSuccess?.()
      if(videoBorder) {
        videoBorder.style.border = "1px solid #ccc"
      }
    }
  }
  
  render() {
    const isDisplayed = this.props.previewIsDisplayed ? "inline" : "none";
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      playbackRate,
      pip,
    } = this.state;
    return (
      <div className={style.videoEl} ref={this.myRef} onClick={this.handleVideoClick.bind(this)}>
        <PlayPauseIcons 
           showPrevButton={this.props.showPrevButton}
           showNextButton={this.props.showNextButton}
           handlePlayPause={(e) => this.handlePlayPause(e)}
           handleNextBtn={(e) => this.handleNextBtn(e)}
           handlePrevBtn={(e) => this.handlePrevBtn(e)}
           state={this.state}
        />
        <ReactPlayerControls
        isMobileDevice
        mobileControls
        autoplay={this.props.autoplay}
        cover={cover}
        handleClickFullscreen={(e) => this.handleClickFullscreen(e)}
        handlePlayPause={(e) => this.handlePlayPause(e)}
        handlePrevBtn={(e) => this.handlePrevBtn(e)}
        handleSeekChange={(e) => this.handleSeekChange(e)}
        handleSeekMouseDown={(e) => this.handleSeekMouseDown(e)}
        handleSeekMouseUp={(e) => this.handleSeekMouseUp(e)}
        handleSetPlaybackRate={(e) => this.handleSetPlaybackRate(e)}
        myRef={this.myRef}
        played={played}
        setState={(state) => this.setState(state)}
        state={this.state}
        volume={this.state?.volume}
        handleNextBtn={(e) => this.handleNextBtn(e)}
        showPrevButton={this.props.showPrevButton}
        showNextButton={this.props.showNextButton}
        hideAutoplay={this.props.hideAutoplay}
        dispatchAutoplayToggle={(e) => this.props.dispatchAutoplayToggle(e)}
        handleToggleMuted={() => this.handleToggleMuted()}
        handleVolumeChange={(e) => this.handleVolumeChange(e)}
        myRefautoPlay={this.myRefautoPlay}
        />
        <ReactPlayer
          ref={this.ref.bind(this)}
          style={{ display: isDisplayed }}
          width="100%"
          height="100%"
          url={url}
          pip={false}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playsinline={true}
          webkit-playsinline={true}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={this.handleOnVideoReady.bind(this)}
          onStart={() => console.log("onStart")}
          onPlay={this.handlePlay}
          onEnablePIP={this.handleEnablePIP}
          onDisablePIP={this.handleDisablePIP}
          onPause={this.handlePause}
          onBuffer={() => console.log("onBuffer")}
          onPlaybackRateChange={this.handleOnPlaybackRateChange}
          onSeek={(e) => console.log("onSeek", e)}
          onEnded={this.handleEnded}
          onError={(e) => {
            if(this.props.videoError) this.props.videoError(e)
          }}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          config={{ file: { attributes: { disablepictureinpicture: true }}}}
          disablePictureInPicture={true}
        />
        <MobileDuration
          played={played}
          handleSeekMouseDown={(e) => this.handleSeekMouseDown(e) }
          handleSeekChange={(e) => this.handleSeekChange(e) }
          handleSeekMouseUp={ (e) => this.handleSeekMouseUp(e)}
        />
        <ReactPlayerControls
        autoplay={this.props.autoplay}
        cover={cover}
        handleClickFullscreen={(e) => this.handleClickFullscreen(e)}
        handlePlayPause={(e) => this.handlePlayPause(e)}
        handlePrevBtn={(e) => this.handlePrevBtn(e)}
        handleSeekChange={(e) => this.handleSeekChange(e)}
        handleSeekMouseDown={(e) => this.handleSeekMouseDown(e)}
        handleSeekMouseUp={(e) => this.handleSeekMouseUp(e)}
        handleSetPlaybackRate={(e) => this.handleSetPlaybackRate(e)}
        myRef={this.myRef}
        played={played}
        setState={(state) => this.setState(state)}
        state={this.state}
        volume={this.state?.volume}
        handleNextBtn={(e) => this.handleNextBtn(e)}
        showPrevButton={this.props.showPrevButton}
        showNextButton={this.props.showNextButton}
        hideAutoplay={this.props.hideAutoplay}
        dispatchAutoplayToggle={(e) => this.props.dispatchAutoplayToggle(e)}
        handleToggleMuted={() => this.handleToggleMuted()}
        handleVolumeChange={(e) => this.handleVolumeChange(e)}
        myRefautoPlay={this.myRefautoPlay}
        />
      </div>
    );
  }


  componentWillUnmount() {
    if(this.html5VideoPlayer.current && !canFullScreen()) {
      this.html5VideoPlayer.current.removeEventListener('webkitendfullscreen',this.handleFullscreenChange);
      this.html5VideoPlayer.current.removeEventListener('fullscreenchange',this.handleFullscreenChange);
      this.html5VideoPlayer.current.removeEventListener('leavepictureinpicture',this.turnOffFullscreenMode);  
    }
    
    if(canFullScreen()) { 
      screenfull.off('change',this.fullscreenchanged.bind(this))
    }
    
    if(this.mobileControlRef.current) {
      clearTimeout(this.mobileControlRef.current)
    }
    if (this.timeoutRef.current) {
      clearTimeout(this.timeoutRef.current);
    }
  }

  fullscreenchanged(event) {
   console.log("calling change")
        this.setState((state) => {
          return {isFullScreen: !state.isFullScreen}
        })
  }
  markFullscreenclose() {
    this.setState((state) => {
      return {isFullScreen: false}
    })
  }

  componentDidMount() {
    if(canFullScreen()) {
      screenfull.on('change',this.fullscreenchanged.bind(this))
    }
    if(!this.state.playing) {
      this.isPlayStateChanged.current = true
      this.toggleOpacity(1)
    }

    this.myRefautoPlayInit.current = true;
    this.myRefautoPlay.current = false;

    if (this.props?.playerState) {
      this.props.playerState(this.state, this.setState.bind(this))
    }
    this.timeoutRef.current = setTimeout(() => {
      const video = this.myRef.current.querySelector('video');
      video.playsinline = true;
      video.autoplay = true;
      video.muted = false;
      const isPlayed = video.play()
      this.setState({
        volume: this.props?.autoVideoVolume ? parseFloat(this.props?.autoVideoVolume) : 1,
        playbackRate: this.props?.autoVideoSpeed ? parseFloat(this.props?.autoVideoSpeed) : 1.0,
        muted: this.props?.mute ? this.props?.mute : false
      })
      if (isPlayed instanceof Promise) {
        isPlayed.then(() => {
          this.setState({ playing: true })
        }).catch((err) => {
          console.log({ err });
        })
      }
    }, 1000)
  }
}

VideoPlayer.propTypes = {
  mediaUrl: PropTypes.string,
  onTimeUpdate: PropTypes.func,
  onClick: PropTypes.func,
  videoRef: PropTypes.object.isRequired,
  onLoadedDataGetDuration: PropTypes.func,
  previewIsDisplayed: PropTypes.bool,
  previewViewWidth: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    isVideoautoPlaySuccess: state.nodes.isVideoautoPlaySuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAutoplayToggle: (payload, fail) => {
      dispatch(videoAutoplayRequest({
        ...payload,
        fail: (error) => {
          fail(error)
        }
      }))
    },
    updateUserPreferences: (payload, fail) => {
      dispatch(updateVidePreference({
        data: payload,
        fail: (error) => {
          fail(error)
        }
      }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(VideoPlayer));
