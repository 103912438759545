
import { GET_SEARCH_RESULTS } from "Redux/Actions";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { API } from "Services/Api/Constants";
import { getRequest } from "Shared/Axios";
import {takeLatest, all, put, debounce} from 'redux-saga/effects';
import { saveResults } from '../Actions/SearchEvents';

function* getResults({payload : {page, search , success=()=>{},fail=()=>{}}}) {
  try {
      yield put(startLoader())
      const {status,data={}} = yield getRequest({
          API: API.GET_SEARCH_RESULTS+'?search='+encodeURI(search)+'&page='+page
      })
      if(status === 400 || status === 401){
        fail(data);
    }
    else if(status === 200 || status === 201){
        yield put(saveResults({ data: data.data, count: data.count }))
        success(data);
    }
      yield put(stopLoader())
  } catch(error) {
      fail(error)
      yield put(stopLoader())
  }
}

function* nodeSaga(){
    yield all([
        takeLatest(GET_SEARCH_RESULTS,getResults)
    ])
}
export default nodeSaga;
