import { lazy } from "react";
import { ROUTE_CONSTANTS } from '../Shared/Routes';
const Login = lazy(()=> import("../Views/Authentication/Login"))
const SignUp = lazy(()=> import("../Views/Authentication/SignUp"))
const LandingPage = lazy(()=> import("../Views/Authentication/LandingPage"))
const ForgetPassword = lazy(()=> import("../Views/Authentication/ForgetPassword"))
const OTP = lazy(()=> import("../Views/Authentication/OTP"))
const ResetPassword = lazy(()=> import("../Views/Authentication/ResetPassword"))
const TwitterLogin = lazy(() => import("../Views/Authentication/TwitterLogin"))
const QuestionnaireTwo = lazy(() => import("../Views/Authenticated/QuestionnaireTwo"))
const QuestionnaireOne = lazy(() => import("../Views/Authenticated/QuestionnaireOne"))
const SearchResults = lazy(() => import("../Views/Authentication/SearchResults"))

// const TwitterLogin = lazy(() => import("../Views/Authentication/TwitterLogin"))

export const AUTH_ROUTES = [
  {
    path: "/login",
    component: Login,
    title: "Login",
    exact: true
  },
  {
    path: "/signup",
    component: SignUp,
    title: "Signup",
    exact: true
  },
  {
    path: "/",
    component: LandingPage,
    title: "Cancer Light",
    exact: true
  },
  {
    path: "/forgetpassword",
    component: ForgetPassword,
    title: "Forgot Password",
    exact: true
  },
  {
    path: "/otp",
    component: OTP,
    title: "Landing Page",
    exact: true
  },
  {
    path: "/changepassword/:id/:token",
    component: ResetPassword,
    title: "Reset Password",
    exact: true,
  },
  {
    path: ROUTE_CONSTANTS.QUESTION_ONE,
    component: QuestionnaireOne,
    title: "Questionnaire",
  },
  {
    path: ROUTE_CONSTANTS.QUESTION_TWO,
    component: QuestionnaireTwo,
    title: "QuestionnaireTwo",
  },
  {
    path: "/twitterlogin/:token",
    component: TwitterLogin,
    title: "Login",
    exact: true
  },
  {
    path:ROUTE_CONSTANTS.SEARCH,
    component: SearchResults,
    title: "Login",
    exact: true
  }
];
