import React from "react";
import PropTypes from "prop-types";
import TimedTextEditor from "../timed-text-editor";
import whiteNodeVideo from '../../../assets/video/white_noise.mp4';


import VideoPlayer from "../video-player";


import { secondsToTimecode } from "../util/timecode-converter";

import "./style.scss";

class TranscriptEditor extends React.Component {
  constructor(props) {
    console.log(props, "props");
    super(props);
    this.videoRef = React.createRef();

    this.state = {

      currentTime: 0,
      transcriptData: null,
      isScrollIntoViewOn: false,
      showSettings: true,
      showShortcuts: false,
      showExportOptions: false,
      isPauseWhileTypingOn: true,
      rollBackValueInSeconds: 15,
      timecodeOffset: 0,
      showTimecodes: true,
      showSpeakers: true,
      previewIsDisplayed: true,
      mediaDuration: "00:00:00:00",
      gridDisplay: null
    };

    this.timedTextEditorRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps) {

    if (nextProps.transcriptData !== null) {
      return {
        transcriptData: nextProps.transcriptData
      };

    }

    return null;
  }


  // performance optimization
  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.mediaUrl !== this.props.mediaUrl) {
      return true;
    }

    return nextState !== this.state;
  };

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => {
    let gridDisplay = {
      display: "grid",
      gridTemplateColumns: "1fr 3fr",
      gridColumnGap: "1em"
    };

    let displayMedia = null;
    // if the mediaUrl is for an audio file, then extend TimedTextEditor to be full width
    if (this.props.mediaType === "audio") {
      console.log("this.props.mediaType", this.props.mediaType);
      gridDisplay = null;
      displayMedia = { display: "none" };
    }
    // Handeling mobile view
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0);

    if (width <= 767) {
      gridDisplay = null;
    }
    this.setState({
      gridDisplay,
      displayMedia
    });

  };

  // eslint-disable-next-line class-methods-use-this
  handleWordClick = (startTime) => {
    if (this.props.handleAnalyticsEvents) {
      this.props.handleAnalyticsEvents({
        category: "TranscriptEditor",
        action: "doubleClickOnWord",
        name: "startTime",
        value: secondsToTimecode(startTime)
      });

    }

    this.setCurrentTime(startTime);
  };

  // eslint-disable-next-line class-methods-use-this
  handleTimeUpdate = (e) => {
    const currentTime = e.target.currentTime;
    this.setState({
      currentTime
    });

  };

  handlePlayMedia = (isPlaying) => {
    this.playMedia(isPlaying);
  };

  handleIsPlaying = () => {
    return this.isPlaying();
  };

  onLoadedDataGetDuration = (e) => {
    const currentDuration = e.target.duration;
    const currentDurationWithOffset =
      currentDuration + this.state.timecodeOffset;
    const durationInSeconds = secondsToTimecode(currentDurationWithOffset);

    this.setState({
      mediaDuration: durationInSeconds
    });


    if (this.props.handleAnalyticsEvents) {
      this.props.handleAnalyticsEvents({
        category: "TranscriptEditor",
        action: "onLoadedDataGetDuration",
        name: "durationInSeconds-WithoutOffset",
        value: secondsToTimecode(currentDuration)
      });

    }
  };



  handleAutoSaveChanges = (data) => {
    // making `TranscriptEditor` - `handleAutoSaveChanges` optional
    if (this.props.handleAutoSaveChanges) {
      this.props.handleAutoSaveChanges(data);
    }
  };



  render() {
    const videoPlayer =
      <>
        <VideoPlayer
          videoError={() => {
            this.setState({...this.state, videoError: true})        
          }}
          isPreview={this.props.isPreview}
          autoVideoSpeed={this.props.autoVideoSpeed}
          autoVideoVolume={this.props.autoVideoVolume}
          onHandlePlayPause={this.props.onHandlePlayPause}
          handleSeekChange={this.props.handleSeekChange}
          noAutoPlay={this.props.noAutoPlay}
          playerState={this.props.playerState}
          autoPlayChanged={this.props.autoPlayChanged}
          autoplay={this.props.autoplay}
          mediaUrl={this.state.videoError ? whiteNodeVideo : this.props.mediaUrl  }
          onTimeUpdate={this.handleTimeUpdate}
          videoRef={this.videoRef}
          previewIsDisplayed={this.state.previewIsDisplayed}
          onLoadedDataGetDuration={this.onLoadedDataGetDuration}
          videoEnded={(e) => {
            this.setState({...this.state, videoError: false})  
            this.props.videoEnded(e)
          }}
          selectedVideoEnded={(e) => {
            this.setState({...this.state, videoError: false})  
            this.props.selectedVideoEnded(e)
          }}
          previousVideo={(e) => {
            this.setState({...this.state, videoError: false})  
            this.props.previousVideo(e)
          }}
          hideAutoplay={this.props.hideAutoplay}
          mute={this.props.mute}
          showPrevButton={this.props.showPrevButton}
          showNextButton={this.props.showNextButton}
          onloadSuccess={this.props.onloadSuccess} />

      </>;
    // export format for `handleAutoSaveChanges` is assigned with `autoSaveContentType`
    // but if that's not specified  it looks at  `sttJsonType`
    // if that's not specified either, it falls back on `draftjs`.
    let contentFormat = "draftjs";
    if (this.props.autoSaveContentType) {
      contentFormat = this.props.autoSaveContentType;
    } else if (this.props.sttJsonType) {
      contentFormat = this.props.sttJsonType;
    }

    const timedTextEditor =
      <TimedTextEditor
        fileName={this.props.fileName}
        transcriptData={this.state.transcriptData}
        timecodeOffset={this.state.timecodeOffset}
        onWordClick={this.handleWordClick}
        playMedia={this.handlePlayMedia}
        isPlaying={this.handleIsPlaying}
        currentTime={this.state.currentTime}
        isEditable={this.props.isEditable}
        spellCheck={this.props.spellCheck}
        sttJsonType={this.props.sttJsonType}
        mediaUrl={this.props.mediaUrl}
        isScrollIntoViewOn={this.state.isScrollIntoViewOn}
        isPauseWhileTypingOn={this.state.isPauseWhileTypingOn}
        showTimecodes={this.state.showTimecodes}
        showSpeakers={this.state.showSpeakers}
        ref={this.timedTextEditorRef}
        handleAnalyticsEvents={this.props.handleAnalyticsEvents}
        handleAutoSaveChanges={this.handleAutoSaveChanges}
        autoSaveContentType={contentFormat}
        title={this.props.title ? this.props.title : Date.now()} />;


    return (
      <div className="Video_player_column">
        <div className="video_node_secn">
          <div className="ratio ratio-21x9"
          // className={style.aside} style={this.state.displayMedia}
          >
            {this.props.mediaUrl ? videoPlayer : null}
          </div>
          {/* <div className="video_options">
            {this.props.mediaUrl ? header : null}
            </div> */}


          <main
          // className={
          //   this.props.mediaType === "audio"
          //     ? style.mainWithAudiio
          //     : style.main
          // }
          >

            {this.props.mediaUrl && this.props.transcriptData ?
              timedTextEditor :
              null}
          </main>

        </div>
      </div>);

  }
}


TranscriptEditor.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  mediaUrl: PropTypes.string,
  isEditable: PropTypes.bool,
  spellCheck: PropTypes.bool,
  sttJsonType: PropTypes.string,
  handleAnalyticsEvents: PropTypes.func,
  fileName: PropTypes.string,
  transcriptData: PropTypes.object,
  mediaType: PropTypes.string
};


export default TranscriptEditor;